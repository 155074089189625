<script lang="ts" setup>
import {useSettingsStore} from "~/stores/settings";
import {useElementor, useWp} from "~/composables/Redmonkey";
import TitleBase from "~/components/Redmonkey/Base/TitleBase.vue";
import type {WpMedia} from "~/integration/wordpress/types";

const settingsStore = useSettingsStore();

const {
  getSettingsValue: getGeneralSettingValue,
  getMediaUrlFromMedia,
  getMediaAltFromMedia,
  getMediaWidthFromMedia,
  getMediaHeightFromMedia
} = useWp()

const {
  getSettingsValue,
  isThatDefault
} = useElementor();

const props = defineProps({
  element: Object
})


const getTitle = () => {
  return getSettingsValue(props.element, 'title') || 'Про нас';
}

const getAbout = (num: number) => {
  if(!isThatDefault(props.element, 'use_default')){
    return getSettingsValue(props.element, `about_us_${num}`);
  }

  return getGeneralSettingValue(settingsStore?.redmonkeySettings, `aboutUs${num}`, 'aboutUs')
}

const getImage = () => {
  if(!isThatDefault(props.element, 'use_default')){
    return {
      url: getSettingsValue(props.element, 'image', 'url'),
      alt: getSettingsValue(props.element, 'image', 'alt'),
      width: 'auto',
      height: 'auto'
    }
  }

  const image = getGeneralSettingValue(settingsStore?.redmonkeySettings, 'aboutUsImage', 'aboutUs').node as WpMedia;

  return  {
    url: getMediaUrlFromMedia(image),
    alt: getMediaAltFromMedia(image),
    width: getMediaWidthFromMedia(image),
    height: getMediaHeightFromMedia(image)
  }
}

const getCaption = () => {
  if(!isThatDefault(props.element, 'use_default')){
    return getSettingsValue(props.element, 'caption');
  }

  return getGeneralSettingValue(settingsStore?.redmonkeySettings, 'aboutUsCaption', 'aboutUs');
}
</script>

<template>
  <div class="about-us" id="about-us">
    <TitleBase :title="getTitle()" star-position="right"/>
    <div class="about-us-content flex wrap">
      <div class="image" v-if="getImage().url">
        <picture>
          <img :src="getImage().url" :alt="getImage().alt" :width="getImage().width" :height="getImage().height" />
        </picture>
        <h4 v-if="getCaption()">{{ getCaption()}}</h4>
      </div>
      <div class="about-blocks flex">
        <div class="about about1" v-if="getAbout(1)" v-html="getAbout(1)"></div>
        <div class="about about2" v-if="getAbout(2)" v-html="getAbout(2)"></div>
        <div class="about about3" v-if="getAbout(3)" v-html="getAbout(3)"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "assets/css/source/components/widgets/about_us";
</style>